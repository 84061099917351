.MuiSelect-multiple {
  padding: 9px !important;
}
/* start emoji__input */
#emoji__input {
  width: 100%;
  margin-right: -2%;
}
#emoji__input > div {
  width: 100%;
}
#emoji__input > div > em-emoji-picker {
  width: 100%;
}
/* end emoji__input */
/* start ticket */

[dir="rtl"] #ticket__img,
[dir="rtl"] #ticket__title {
  order: 1;
}
[dir="rtl"] #ticket__img div {
  margin-right: 1rem !important;
}
[dir="rtl"] #ticket__text {
  order: -1;
  justify-content: flex-start;
}
[dir="rtl"] #ticket__meas {
  width: 100%;
  order: 1;
  text-align: end;
}
[dir="rtl"] #ticket__close-chat {
  transform: rotateY(180deg);
}
/* end ticket */
