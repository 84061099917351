.queues__head {
  display: flex;
  align-items: center;
  padding: 7px;
}
.queues__head > h5 {
  margin-bottom: 0 !important;
}
[dir="rtl"] .queues__head > div {
  margin-right: auto;
  margin-left: 0 !important;
}
